import styled from 'styled-components/native';
import Constants from 'expo-constants';
import { LinearGradient } from 'expo-linear-gradient';

export const Title = styled.Text`
  font-size: 14px;
  margin-left: 10px;

  font-family: ${({ theme }) => theme.FONTS.TEXT};
  color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
`;

export const TopContainer = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const IconButton = styled.TouchableOpacity`
  visibility: false;
`;

export const SearchContainer = styled.View`
  margin-top: 12px;
`;

export const InputContainer = styled.View`
  background-color: white;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
`;

export const Input = styled.TextInput`
  flex: 1;
  padding: 10px 0;
`;

export const ConfirmButton = styled.TouchableOpacity``;

export const ButtonLabel = styled.Text`
  color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_LIGHT};
  font-weight: 500;
`;

export const Container = styled(LinearGradient).attrs(({ theme }) => ({
  colors: theme.COLORS.GRADIENT,
  start: { x: 1, y: 0 },
  end: { x: 0.5, y: 0.5 },
}))`
  width: 100%;
  padding: ${Constants.statusBarHeight + 15}px 20px 20px;
`;

export const ProfileArea = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ProfileImage = styled.Image`
  width: 28px;
  height: 28px;
  border-radius: 12px;
  margin-right: 10px;
`;

export const UserName = styled.Text`
  font-size: 11px;
  font-weight: 500;
  color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
`;
