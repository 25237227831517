import React, { ReactNode } from 'react';
import { OrdersProvider } from './Orders';
import { OrderItemEventsProvider } from './OrderItemEventsProvider';
import { OrdersViewProvider } from './OrdersView';
import { AuthContext } from './Auth';

type AppProviderProps = {
  children: ReactNode;
};

export function AppProvider({ children }: AppProviderProps) {
  return (
    <OrdersProvider>
      <OrdersViewProvider>
        <AuthContext.Consumer>
          {(auth) => (
            <OrderItemEventsProvider isLoggedIn={Boolean(auth.user)}>
              {children}
            </OrderItemEventsProvider>
          )}
        </AuthContext.Consumer>
      </OrdersViewProvider>
    </OrdersProvider>
  );
}
