import React from 'react';

import { NavigationContainer } from '@react-navigation/native';
import { OrdersStackNavigator } from './orders.stack.routes';
import { GuestStackNavigator } from './guest.stack.routes';
import { AuthContext } from '../hooks/Auth';

export function Routes() {
  return (
    <NavigationContainer>
      <AuthContext.Consumer>
        {(auth) => (auth.user !== null && auth.isAllowed ? (
          <OrdersStackNavigator />
        ) : (
          <GuestStackNavigator />
        ))}
      </AuthContext.Consumer>
    </NavigationContainer>
  );
}
