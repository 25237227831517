import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  font-size: 14px;
  font-weight: 600;

  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};

  margin-top: 6px;
`;

export const MetaContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MetaInfoPill = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_LIGHT};
  padding: 4px 8px;
`;

export const MetaKey = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-size: 11px;
  font-weight: 600;
  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_LIGHT};
`;

export const MetaValue = styled.Text`
  margin-left: 4px;
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-size: 11px;
  font-weight: 400;
  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_LIGHT};
`;
