import React from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Button, Container, Title } from './styles';
import theme from '../../theme';

interface GoBackHeaderProps {
  title: string;
}

export function GoBackHeader({ title }: GoBackHeaderProps) {
  const navigation = useNavigation();

  return (
    <Container>
      <Button onPress={navigation.goBack}>
        <MaterialIcons
          name="arrow-back"
          size={25}
          color={theme.COLORS.PRIMARY_BRAND_LIGHT}
        />
      </Button>

      <Title>{title}</Title>
    </Container>
  );
}
