import React from 'react';
import { Container, Label, LoadingIcon } from './styles';

export function AppLoading() {
  return (
    <Container>
      <LoadingIcon />
      <Label>Carregando</Label>
    </Container>
  );
}
