import styled, { css } from 'styled-components/native';

interface ButtonType {
  type: 'confirm' | 'cancel';
}

export const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
`;

export const Title = styled.Text`
  color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_DEFAULT};
  font-size: 24px;
  font-weight: 800;
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  margin-top: 12px;
  text-align: center;
`;

export const SummaryText = styled.Text`
  color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_DEFAULT};
  font-size: 14px;
  font-weight: 700;
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  margin-top: 12px;
  text-align: center;
`;

export const Button = styled.TouchableOpacity<ButtonType>`
  align-items: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100%;
  margin-top: 20px;
  ${(props) => props.type === 'confirm'
    && css`
      background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHTNESS};
      border: 1px ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
    `}
  ${(props) => props.type === 'cancel'
    && css`
      background-color: rgba(255, 179, 179, 0.2);
      border: 1px rgba(195, 46, 46, 0.2);
    `}
`;

export const ButtonText = styled.Text<ButtonType>`
  font-size: 18px;
  font-weight: 700;
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  text-align: center;
  ${(props) => props.type === 'confirm'
    && css`
      color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_DEFAULT};
    `}

  ${(props) => props.type === 'cancel'
    && css`
      color: #d44949;
    `}
`;
