import styled, { css } from 'styled-components/native';

export const Container = styled.KeyboardAvoidingView`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
`;

export const IssueTypeContainer = styled.View`
  margin-top: 16px;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`;

export const Label = styled.Text`
  text-align: left;
  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
  font-size: 16px;
  font-weight: 800;
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  margin-bottom: 9px;
`;

export const SwitchLabel = styled.Text`
  text-align: left;
  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
  font-size: 16px;
  font-weight: 800;
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  margin-left: 15px;
`;

export const IssueCounterContainer = styled.View`
  width: 100%;
  display: flex;
`;

export const SwitchContainer = styled.View`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const ObservationsInputContainer = styled.View`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
`;

export const ObservationsInput = styled.TextInput`
  width: 100%;
  min-height: 76px;
  padding: 10px;

  background: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
  border-radius: 4px;
`;

export const Button = styled.TouchableOpacity`
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;

  margin-top: 24px;
  margin-bottom: 50px;
  height: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_DEFAULT};

  ${(props) => props.disabled
    && css`
      opacity: 0.5;
    `}
`;

export const ButtonTitle = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
`;
