/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useCallback, useEffect, useState } from 'react';
import { useRoute, useNavigation, useIsFocused } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';
import {
  Button, ButtonText, Container, SummaryText, Title,
} from './styles';
import { ConfirmDispatchOrderProps } from '../../types/navigation';
import { useOrders } from '../../hooks/Orders';
import { AppLoading } from '../../components/AppLoading';
import { Item, Order } from '../../types/types';
import theme from '../../theme';

export function ConfirmDispatchOrderForm() {
  const route = useRoute();
  const { postDispatchOrderEvent } = useOrders();
  const { goBack } = useNavigation();
  const { id } = route.params as ConfirmDispatchOrderProps;
  const isFocused = useIsFocused();
  const [loading, setLoading] = useState(false);
  const orders = useOrders();
  const [order, setOrder] = useState<Order>();

  const fetchOrder = useCallback(async (): Promise<void> => {
    const fetchedOrder = await orders.getOrderById(id);

    setOrder(fetchedOrder);
  }, []);

  useEffect(() => {
    fetchOrder();
  }, [isFocused]);

  const finishConfirmation = () => {
    setLoading(false);
    goBack();
  };

  async function handleConfirmOrderDispatch() {
    setLoading(true);
    postDispatchOrderEvent(id).finally(finishConfirmation);
  }

  const rupturedItems = order?.items.filter((i) => i.fulfilment_status === 'NOT_FULFILLED');

  function ruptureListItem(i: Item) {
    return (
      <SummaryText>
        <AntDesign
          name="exclamationcircle"
          size={14}
          color={theme.COLORS.SECONDARY_BRAND_DARK}
        />

        {` ${i.sku.description} - ${i.shipped_quantity} ${i.sku.unit}\n`}
      </SummaryText>
    );
  }

  function rupturedItemsWarning(itemList: Item[]) {
    return (
      <SummaryText>
        Pedido com as seguintes rupturas:
        {'\n\n'}
        {itemList?.map((i) => ruptureListItem(i))}
      </SummaryText>
    );
  }
  if (loading) return <AppLoading />;

  return (
    <Container>
      <Title>Atenção!</Title>
      <SummaryText>
        Ao confirmar o despacho você autoriza a emissão do pedido #
        {`${order?.id_commercial} `}
        para o cliente
        {' '}
        {order?.contact_company.name}
        {'\n'}
        {
          rupturedItems?.length
            ? rupturedItemsWarning(rupturedItems)
            : '\nPedido sem rupturas!'
        }
      </SummaryText>
      <Button
        type="confirm"
        onPress={() => {
          handleConfirmOrderDispatch();
        }}
      >
        <ButtonText type="confirm">Confirmar</ButtonText>
      </Button>
      <Button type="cancel" onPress={finishConfirmation}>
        <ButtonText type="cancel">Cancelar</ButtonText>
      </Button>
    </Container>
  );
}
