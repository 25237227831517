/* eslint-disable camelcase */
import moment from 'moment';
import React, { useEffect } from 'react';
import { AppLoading } from '../../components/AppLoading';
import { OrderCard } from '../../components/OrderCard';
import { ScrollableList } from '../../components/ScrollableList';
import { SubHeader } from '../../components/SubHeader';
import { useOrders } from '../../hooks/Orders';
import { useOrdersView } from '../../hooks/OrdersView';

export function PendingOrders() {
  const { loadingOrders, loadOrders } = useOrders();
  const { pendingOrdersView, refusedOrdersView } = useOrdersView();

  useEffect(() => {
    loadOrders();
  }, []);

  if (loadingOrders) {
    return <AppLoading />;
  }

  const BaseOrdersView = pendingOrdersView.concat(refusedOrdersView)
    .sort((a, b) => a.contact_company.name.trim()
      .localeCompare(b.contact_company.name.trim()))
    .sort((a, b) => a.delivery_category.timewindow_end
      ?.localeCompare(b.delivery_category.timewindow_end));
  const pickerViewedOrders = [
    ...BaseOrdersView.filter((i) => i.status === 'PICKING_REFUSED'),
    ...BaseOrdersView.filter((i) => i.status !== 'PICKING_REFUSED'),
  ];

  return (
    <ScrollableList>
      <SubHeader />
      {pickerViewedOrders.map((order) => {
        const {
          id_order, contact_company, deliver_at, id_commercial, items, delivery_category, status,
        } = order;
        return (
          <OrderCard
            key={id_order}
            contactCompanyName={contact_company.name}
            idOrder={id_order}
            deliveredAt={moment(deliver_at).format('LL')}
            deliveryWindow={delivery_category}
            orderNumber={id_commercial}
            productsCount={items.length}
            isPendingOrder
            status={status}
          >
            {undefined}
          </OrderCard>
        );
      })}
    </ScrollableList>
  );
}
