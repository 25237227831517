import styled from 'styled-components/native';

export const Button = styled.TouchableOpacity`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
  border-radius: 4px;
  border: 1px ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
  margin-top: 8px;
  margin-bottom: 4px;
  height: 33px;
`;

export const DispatchButton = styled.TouchableOpacity`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  border: 1px ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
  margin-top: 8px;
  margin-bottom: 4px;
  height: 33px;
`;

export const ButtonTitle = styled.Text`
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_DEFAULT};
`;
