import styled, { css } from 'styled-components/native';

type Status = {
  color: string;
};

export const Container = styled.View`
  display: flex;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
  padding: 16px;
  margin-top: 16px;
  border-radius: 4px;
  border: 1px ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
`;

export const PhotoContainer = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 55px;
  border: 1px ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHTNESS};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
`;

export const ItemHeaderContainer = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ItemInformationContainer = styled.View`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 13px;
  height: 55px;
`;

export const ItemTitle = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;

export const OrderedQuantityText = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.SECONDARY_BRAND_DARKNESS};

  margin-top: 6px;
`;

export const OrderStatusContainer = styled.View`
  border-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHTNESS};

  display: flex;
  flex-direction: row;
  width: 100%;
  height: 33px;
  justify-content: space-between;
  align-items: center;
  border-width: 1px;
  border-radius: 4px;
  margin-top: 8px;
  padding: 6.5px 16px 6.5px 16px;
`;

export const SortedText = styled.Text`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 700;
  margin-right: 6px;
  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;

export const OrderItemStatusText = styled.Text<Status>`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 700;
  margin-right: 6px;
  text-align: center;

  ${(props) => props.color
    && css`
      color: ${props.color};
    `}
`;

export const OrderItemObservationText = styled.Text`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 700;
  margin-right: 6px;
  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;
