import React, { ReactNode } from 'react';
import { Image } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import {
  Container,
  ItemHeaderContainer,
  ItemInformationContainer,
  ItemTitle,
  PhotoContainer,
  OrderItemStatusText,
  SortedText,
  OrderedQuantityText,
  OrderStatusContainer,
  OrderItemObservationText,
} from './styles';
import defaultImage from '../../assets/no-image.png';
import { OrderItemCapabilities } from '../../types/types';
import theme from '../../theme';

type OrderItemCardProps = {
  skuName: string;
  skuQuantity: number;
  skuUnit: string;
  quantityOrdered: number;
  quantitySorted: number;
  orderItemStatus: string;
  orderItemObservation: string;
  children: ReactNode | undefined;
  capabilities: OrderItemCapabilities;
  itemCurrentStatus?: string;
};

const statusColor: { [status: string]: string } = {
  FULFILLED: theme.COLORS.PRIMARY_BRAND_LIGHT,
  NOT_FULFILLED: theme.COLORS.SECONDARY_BRAND_DARK,
  PARTIALLY_FULFILLED: theme.COLORS.SECONDARY_BRAND_DARK,
  UNDEFINED: theme.COLORS.BACKGROUND_BRAND_LIGHT,
};

const approvalStatusColor: { [status: string]: string } = {
  PICKING_APPROVED: theme.COLORS.PRIMARY_BRAND_LIGHT,
  PICKING_REFUSED: theme.COLORS.SECONDARY_BRAND_DARK,
};

const status: { [status: string]: string } = {
  PARTIALLY_FULFILLED: 'Ruptura',
  FULFILLED: 'Revisado',
  NOT_FULFILLED: 'Ruptura Total',
};

const approvalStatus: { [status: string]: string } = {
  PICKING_APPROVED: 'Aprovado',
  PICKING_REFUSED: 'Recusado',
};

const statusIconMap: { [status: string]: ReactNode } = {
  PARTIALLY_FULFILLED: (
    <AntDesign
      name="exclamationcircle"
      size={16}
      color={statusColor.NOT_FULFILLED}
    />
  ),
  FULFILLED: (
    <AntDesign name="checkcircle" size={16} color={statusColor.FULFILLED} />
  ),
  UNDEFINED: (
    <AntDesign name="checkcircle" size={16} color={statusColor.UNDEFINED} />
  ),
  NOT_FULFILLED: (
    <AntDesign
      name="exclamationcircle"
      size={16}
      color={statusColor.NOT_FULFILLED}
    />
  ),
};

const approvalStatusIconMap: { [status: string]: ReactNode } = {
  PICKING_APPROVED: (
    <AntDesign name="checkcircle" size={16} color={approvalStatusColor.PICKING_APPROVED} />
  ),
  PICKING_REFUSED: (
    <AntDesign name="warning" size={16} color={approvalStatusColor.PICKING_REFUSED} />
  ),
};

export function OrderItemCard({
  skuName,
  skuQuantity,
  skuUnit,
  orderItemStatus,
  orderItemObservation,
  children,
  quantityOrdered,
  quantitySorted,
  capabilities,
  itemCurrentStatus,
}: OrderItemCardProps) {
  return (
    <Container>
      <ItemHeaderContainer>
        <PhotoContainer>
          <Image source={defaultImage} defaultSource={defaultImage} />
        </PhotoContainer>
        <ItemInformationContainer>
          <ItemTitle>{skuName}</ItemTitle>
          <OrderedQuantityText>
            {`${quantityOrdered}x encomendados - Volume total: ${skuQuantity * quantityOrdered} ${skuUnit}`}
          </OrderedQuantityText>
        </ItemInformationContainer>
      </ItemHeaderContainer>
      <OrderStatusContainer>
        <SortedText>
          {capabilities.can_update_item
            ? `${quantitySorted} de ${quantityOrdered} enviados`
            : `${quantitySorted}x`}
        </SortedText>

        {
          (itemCurrentStatus && ['PICKING_APPROVED', 'PICKING_REFUSED'].includes(itemCurrentStatus))
            ? (
              <OrderItemStatusText color={approvalStatusColor[itemCurrentStatus || '']}>
                {approvalStatus[itemCurrentStatus || '']}
                {' '}
                {approvalStatusIconMap[itemCurrentStatus || '']}
              </OrderItemStatusText>
            ) : (
              <OrderItemStatusText color={statusColor[orderItemStatus]}>
                {status[orderItemStatus]}
                {' '}
                {statusIconMap[orderItemStatus]}
              </OrderItemStatusText>
            )
        }
      </OrderStatusContainer>

      {
        orderItemObservation
          ? (
            <OrderStatusContainer>
              <OrderItemObservationText>
                {orderItemObservation}
              </OrderItemObservationText>
            </OrderStatusContainer>
          )
          : null
      }
      {children}
    </Container>
  );
}
