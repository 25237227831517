import React from 'react';
import { Image } from 'react-native';
import {
  Container,
  SummaryContainer,
  PhotoContainer,
  ItemName,
  ItemDescription,
} from './styles';
import defaultImage from '../../assets/no-image.png';
import { Item } from '../../types/types';

type ItemSummaryProps = {
  item: Item;
};

export function ItemSummary({ item }: ItemSummaryProps) {
  return (
    <Container>
      <PhotoContainer>
        <Image source={defaultImage} defaultSource={defaultImage} />
      </PhotoContainer>
      <SummaryContainer>
        <ItemName>{item.sku.description}</ItemName>
        <ItemDescription>{item.ordered_quantity}x encomendados</ItemDescription>
      </SummaryContainer>
    </Container>
  );
}
