import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 63px;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
  border-radius: 8px;
  border: 1px ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
  padding: 9px 16px;
  margin-top: 16px;
`;

export const ProgressBarContainer = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ProgressBarSummary = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_LIGHT};
  font-size: 14px;
  margin-left: 12px;
  font-weight: 600;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  font-size: 14px;
  font-weight: 600;

  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;

export const BoldTitle = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  font-size: 14px;
  font-weight: 700;

  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;
