import axios from 'axios';
import * as Sentry from 'sentry-expo';
import { Session } from '@supabase/supabase-js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { supabaseClient } from './supabase';

const API_URL = String(process.env.API_URL);
const API_AUTH = String(process.env.API_AUTH);
const API_AUTH_HEADER = String(process.env.API_AUTH_HEADER);

const api = axios.create({
  baseURL: API_URL,
  headers: {
    [API_AUTH_HEADER]: API_AUTH,
    'X-App-Name': 'conferito',
    'X-App-Version': process.env.APP_VERSION ?? '',
  },
});

api.interceptors.request.use(async (request) => {
  const sessionString = (await AsyncStorage.getItem('session')) ?? '{}';
  const session: Session = JSON.parse(sessionString);

  Sentry.Browser.addBreadcrumb({
    category: 'api',
    message: 'Api connection error',
    level: Sentry.Browser.Severity.Error,
    data: {
      email: session.user?.email ?? '',
    },
  });

  

  // request.headers!.Authorization = `Bearer ${session.access_token}`;
  request.headers!['X-App-User'] = session.user?.email ?? '';
  return request;
});

api.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const session = supabaseClient.auth.session();
      if (session) {
        await supabaseClient.auth.signOut();
      }
      return;
    }

    return Promise.reject(error);
  },
);

export { api };
