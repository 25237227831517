import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const SummaryContainer = styled.View`
  display: flex;
  flex-direction: column;

  margin-left: 16px;
  justify-content: center;
`;

export const ContactCompanyName = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};

  font-weight: 700;
  font-size: 16px;
  font-style: normal;
`;

export const PhotoContainer = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_LIGHT};

  border: 4px solid ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
`;

export const NewCompanyWarning = styled.Text`
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_DARKNESS};
  border-radius: 8px;
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-size: 14px;
  font-weight: 700;
  padding: 2px 8px;
  margin-bottom: 3px;
  width: 100px;
  color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
`;

export const NotesHeader = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SeeNotesBtn = styled.TouchableOpacity`
  border: 1px solid ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
  border-radius: 4px;
  padding: 2px 8px;
  justify-content: center;
`;

export const SeeNotesBtnText = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;

export const Notes = styled.View`
  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
  border-radius: 8px;
  border: 1px ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
  padding: 9px 16px;
  margin-top: 16px;
`;

export const NotesText = styled.Text`
  margin-top: 5px;
`;

export const NotesTitle = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  font-size: 16px;
  font-weight: 600;

  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;
