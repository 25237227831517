/* eslint-disable camelcase */
import moment from 'moment';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { AppLoading } from '../../components/AppLoading';
import { OrderCard } from '../../components/OrderCard';
import { ScrollableList } from '../../components/ScrollableList';
import { SubHeader } from '../../components/SubHeader';
import { useOrders } from '../../hooks/Orders';
import { useOrdersView } from '../../hooks/OrdersView';
import { ButtonTitle, Button } from './styles';

export function ReviewedOrders() {
  const { loadingOrders } = useOrders();
  const { reviewedOrdersView, refusedOrdersView } = useOrdersView();
  const { navigate } = useNavigation();

  function handleReviewOrder(idOrder: string) {
    navigate('approveOrder', { id: idOrder });
  }

  if (loadingOrders) {
    return <AppLoading />;
  }

  return (
    <ScrollableList>
      <SubHeader />
      {reviewedOrdersView.concat(refusedOrdersView)
        .sort((a, b) => a.contact_company.name.trim()
          .localeCompare(b.contact_company.name.trim()))
        .sort((a, b) => a.delivery_category.timewindow_end
          ?.localeCompare(b.delivery_category.timewindow_end))
        .sort((a, b) => a.delivery_category.timewindow_start
          ?.localeCompare(b.delivery_category.timewindow_start))
        .map((order) => {
          const {
            id_order, contact_company, deliver_at, id_commercial, items, delivery_category, status,
          } = order;
          const hasRupturedItems = order.items.some((i) => i.fulfilment_status === 'NOT_FULFILLED');

          return (
            <OrderCard
              key={id_order}
              contactCompanyName={contact_company.name}
              idOrder={id_order}
              deliveredAt={moment(deliver_at).format('LL')}
              deliveryWindow={delivery_category}
              orderNumber={id_commercial}
              productsCount={items.length}
              cardNavigationDestination="approveOrder"
              status={status}
              hasRupturedItems={hasRupturedItems}
            >

              <Button
                disabled={order.capabilities.can_dispatch_order}
                onPress={() => {
                  handleReviewOrder(id_order);
                }}
              >
                <ButtonTitle>Editar</ButtonTitle>
              </Button>
            </OrderCard>
          );
        })}
    </ScrollableList>
  );
}
