import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';

export const Container = styled(LinearGradient).attrs(({ theme }) => ({
  colors: theme.COLORS.GRADIENT,
  start: { x: 1, y: 0 },
  end: { x: 0.5, y: 0.5 },
}))`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export const HeaderText = styled.Text`
  color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_DARK};
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;
export const LoginArea = styled.View`
  align-items: center;
  background: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.COLORS.SECONDARY_BRAND_LIGHT};
  display: flex;
  height: 200px;
  max-width: 512px;
  justify-content: center;
  width: 90%;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
`;

export const LoginWithGoogleButton = styled.TouchableOpacity`
  align-items: center;
  background: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_LIGHT};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 12px 36px;
  width: 256px;
`;

export const Text = styled.Text`
  color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-weight: bold;
  font-size: 16px;
`;

export const LoadingText = styled.Text`
  color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_DARK};
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

export const NotAllowedText = styled.Text`
  color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_DARK};
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;
