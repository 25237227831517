import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { PendingOrders } from '../screens/PendingOrders';
import { ReviewedOrders } from '../screens/ReviewedOrders';
import { DispatchedOrders } from '../screens/DispatchedOrders';

const { Navigator, Screen } = createMaterialTopTabNavigator();

export function OrdersTabNavigator() {
  return (
    <Navigator>
      <Screen name="Separar" component={PendingOrders} />
      <Screen name="Revisar" component={ReviewedOrders} />
      <Screen name="Despachar" component={DispatchedOrders} />
    </Navigator>
  );
}
