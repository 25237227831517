import styled from 'styled-components/native';

export const Container = styled.View`
  width: 153px;
`;

export const ProgressBarContainer = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ProgressBarSummary = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_DEFAULT};
  font-size: 9px;
  margin-left: 12px;
  font-weight: 700;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  font-size: 14px;
  font-weight: 600;

  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;

export const BoldTitle = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  font-size: 14px;
  font-weight: 700;

  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;
