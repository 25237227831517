import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 90px;
  padding: 16px;
  display: flex;
  flex-direction: row;

  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
  border: 1px ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
  border-radius: 8px;
`;

export const SummaryContainer = styled.View`
  display: flex;
  flex: 1;
  flex-direction: column;

  margin-left: 16px;
`;

export const ItemName = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};

  font-weight: 700;
  font-size: 14px;
  font-style: normal;
`;

export const ItemDescription = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  color: ${({ theme }) => theme.COLORS.SECONDARY_BRAND_DARKNESS};

  margin-top: 10px;
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
`;

export const PhotoContainer = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 55px;
  border: 1px ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHTNESS};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
`;
