import styled, { css } from 'styled-components/native';

type ContainerProps = {
  hideBottomBorder: boolean;
};

export const Container = styled.TouchableOpacity<ContainerProps>`
  width: 100%;
  height: 44px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
  border-radius: 8px;

  padding-right: 17px;
  padding-left: 17px;

  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};

  ${(props) => !props.hideBottomBorder
    && css`
      border-bottom-width: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}
`;

export const SelectedValue = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;

export const DropDownItem = styled.TouchableOpacity`
  width: 100%;
  height: 44px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};

  padding-right: 17px;
  padding-left: 17px;

  border-top-width: 1px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  border-top-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
`;

export const Label = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;

export const DropDownItemContainer = styled.ScrollView`
  display: flex;
  width: 100%;
  max-height: 150px;

  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
  border-top-width: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-radius: 8px;
`;
