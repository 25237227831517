export default {
  COLORS: {
    PRIMARY_BRAND_LIGHT: '#189A66',
    PRIMARY_BRAND_DEFAULT: '#0C7249',
    PRIMARY_BRAND_DARK: '#065F3A',
    PRIMARY_BRAND_DARKNESS: '#074129',
    GREEN_GRADIENT: ['#0C7249', '#074129'],
    SECONDARY_BRAND_LIGHT: '#FFB3B3',
    SECONDARY_BRAND_LIGHTNESS: '#FFB3B3',
    SECONDARY_BRAND_DEFAULT: '#FE4C4C',
    SECONDARY_BRAND_DARK: '#F83838',
    SECONDARY_BRAND_DARKNESS: '#C32E2E',
    RED_GRADIENT: ['#FE4C4C', '#C32E2E'],
    TEXT_BRAND_GREY_DARK: '#50675B',
    BACKGROUND_BRAND_LIGHT: '#D7EADF',
    TEXT_BRAND_GREY_LIGHT: '#738C80',
    BACKGROUND_BRAND_LIGHTNESS: '#F2F7F1',
    BACKGROUND_BRAND_WHITE: '#FFFFFF',
    WARNING_ORANGE: '#ff7800',

    GRADIENT: ['#c12e32', '#f74347'],
  },

  FONTS: {
    TITLE: 'Nunito',
    TEXT: 'Nunito',
  },
};
