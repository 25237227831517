import React, { useState } from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { Container, CounterText, Button } from './styles';
import { isTextInteger } from '../../utils';
import theme from '../../theme';

type CounterProps = {
  counter: number;
  maxValue: number;
  minValue: number;
  disable: boolean;
  onChange(int: number): void;
};

export function QuantitySelector({
  counter,
  onChange,
  maxValue,
  minValue = 0,
  disable,
}: CounterProps) {
  const [visualizedCounter, setVisualizedCounter] = useState<string>(
    minValue.toString(),
  );
  function checkInterval(number: number) {
    return number <= maxValue && number >= minValue;
  }

  function handleIncrease(): void {
    const value = counter + 1;
    if (checkInterval(value)) {
      setVisualizedCounter(value.toString());
      onChange(value);
    }
  }

  function handleDecrease(): void {
    const value = counter - 1;
    if (checkInterval(value)) {
      setVisualizedCounter(value.toString());
      onChange(value);
    }
  }

  function handleInput(text: string): void {
    if (isTextInteger(text)) {
      if (checkInterval(Number(text))) {
        setVisualizedCounter(text);
        onChange(Number(text));
      } else {
        setVisualizedCounter(maxValue.toString());
        onChange(maxValue);
      }
    }
  }

  return (
    <Container>
      <Button
        disabled={disable}
        onPress={() => {
          handleIncrease();
        }}
      >
        <MaterialCommunityIcons
          name="plus"
          size={25}
          color={theme.COLORS.PRIMARY_BRAND_LIGHT}
        />
      </Button>
      <CounterText
        value={visualizedCounter}
        keyboardType="numeric"
        onChangeText={(e) => {
          handleInput(e);
        }}
      />
      <Button
        disabled={disable}
        onPress={() => {
          handleDecrease();
        }}
      >
        <MaterialCommunityIcons
          name="minus"
          size={25}
          color={theme.COLORS.PRIMARY_BRAND_LIGHT}
        />
      </Button>
    </Container>
  );
}
