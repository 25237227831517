import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 14px 25px;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
  border-radius: 4px;

  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
`;

export const CounterText = styled.TextInput`
  font-size: 24px;
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  font-style: normal;
  font-weight: 700;
  text-align: center;

  width: 170px;

  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;

export const Button = styled.TouchableOpacity`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 37px;
  height: 30px;

  background: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHTNESS};
  border: 1px solid ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
  border-radius: 60px;
`;
