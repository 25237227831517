import React from 'react';
import { Image } from 'react-native';
import {
  Container,
  Title,
  MetaContainer,
  MetaInfoPill,
  MetaKey,
  MetaValue,
} from './styles';
import logo from '../../assets/logo.png';
import { useApplicationMeta } from '../../hooks/application-meta';

export function Footer() {
  const meta = Object.entries(useApplicationMeta());
  return (
    <Container>
      <Image source={logo} defaultSource={logo} />
      <Title>© Todos os direitos reservados</Title>
      <MetaContainer>
        {meta.map(([key, value]) => (
          <MetaInfoPill key={key}>
            <MetaKey>{key}</MetaKey>
            <MetaValue>{value}</MetaValue>
          </MetaInfoPill>
        ))}
      </MetaContainer>
    </Container>
  );
}
