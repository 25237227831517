import styled, { css } from 'styled-components/native';

type Hideable = {
  hide: boolean;
};

interface ButtonType {
  type: 'problem' | 'separate' | 'update';
  variableSize?: boolean
}

export const ConfirmDispatchButton = styled.TouchableOpacity<Hideable>`
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;

  margin-top: 24px;
  margin-bottom: 50px;
  height: 56px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_DEFAULT};

  ${(props) => props.disabled
    && css`
      opacity: 0.5;
    `}

  ${(props) => props.hide
    && css`
      display: none;
    `}
`;

export const ConfirmDispatchButtonLabel = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
`;

export const OrderStatusContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};
  border-radius: 8px;
  border: 1px ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
  padding: 16px;
  margin-top: 16px;
`;

export const StatusTitle = styled.Text`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_DEFAULT};
  margin-left: 10px;
`;

export const ButtonContainer = styled.View`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const ItemButton = styled.TouchableOpacity<ButtonType>`
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 136px;

  ${(props) => props.type === 'separate'
    && css`
      background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHTNESS};
      border: 1px ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
    `}

  ${(props) => (props.type === 'problem' || props.type === 'update')
    && css`
      background-color: rgba(255, 179, 179, 0.2);
      border: 1px rgba(195, 46, 46, 0.2);
    `}

    ${(props) => !(props.variableSize)
  && css`
      width: 136px;
      `}

      ${(props) => (props.variableSize)
    && css`
        padding: 0px 10px;
        `}
    

  border-radius: 4px;
`;

export const ButtonText = styled.Text<ButtonType>`
  font-size: 14px;
  font-weight: 700;

  ${(props) => props.type === 'separate'
    && css`
      color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_DEFAULT};
    `}

  ${(props) => (props.type === 'problem' || props.type === 'update')
    && css`
      color: #d44949;
    `}
`;
