/* eslint-disable camelcase */
import moment from 'moment';
import React from 'react';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { AppLoading } from '../../components/AppLoading';
import { OrderCard } from '../../components/OrderCard';
import { ScrollableList } from '../../components/ScrollableList';
import { SubHeader } from '../../components/SubHeader';
import { useOrders } from '../../hooks/Orders';
import { useOrdersView } from '../../hooks/OrdersView';
import { ButtonTitle, Button, DispatchButton } from './styles';
import theme from '../../theme';

export function DispatchedOrders() {
  const { loadingOrders } = useOrders();
  const { dispatchedOrdersView, approvedOrdersView } = useOrdersView();
  const { navigate } = useNavigation();

  function dispatchedIds() {
    return dispatchedOrdersView.map((i) => i.id_order);
  }

  function handleNavigation(idOrder: string) {
    navigate('approveOrder', { id: idOrder });
  }

  function handleDispatchOrder(idOrder: string) {
    navigate('dispatchForm', { id: idOrder });
  }

  if (loadingOrders) {
    return <AppLoading />;
  }

  return (
    <ScrollableList>
      <SubHeader />
      {approvedOrdersView.concat(dispatchedOrdersView)
        .sort((a, b) => a.contact_company.name.trim()
          .localeCompare(b.contact_company.name.trim()))
        .sort((a, b) => a.delivery_category.timewindow_end
          ?.localeCompare(b.delivery_category.timewindow_end))
        .sort((a, b) => a.delivery_category.timewindow_start
          ?.localeCompare(b.delivery_category.timewindow_start))
        .sort(
          (a, b) => (Number(dispatchedIds().includes(a.id_order))
            - Number(dispatchedIds().includes(b.id_order))),
        )
        .map((order) => {
          const {
            id_order, contact_company, deliver_at, id_commercial, items, delivery_category,
          } = order;
          return (
            <OrderCard
              key={id_order}
              contactCompanyName={contact_company.name}
              idOrder={id_order}
              deliveredAt={moment(deliver_at).format('LL')}
              deliveryWindow={delivery_category}
              orderNumber={id_commercial}
              productsCount={items.length}
              cardNavigationDestination="approveOrder"
            >
              {!order.items.every((i) => i.status === 'PICKING_APPROVED') && (
              // warning about orders with not approved items
              <DispatchButton>
                <ButtonTitle>
                  {'Itens não aprovados '}
                  <AntDesign name="warning" size={16} color={theme.COLORS.SECONDARY_BRAND_DARK} />
                </ButtonTitle>
              </DispatchButton>
              )}

              {(
                !dispatchedIds().includes(order.id_order)
                && order.items.every((i) => i.status === 'PICKING_APPROVED')
              )
              && (
                // dispatch button
                <Button onPress={() => {
                  handleDispatchOrder(id_order);
                }}
                >
                  <ButtonTitle>Confirmar despacho 🚚</ButtonTitle>
                </Button>
              )}
              {dispatchedIds().includes(order.id_order) && (
                // inform order is dispatched, lets user see items
                <DispatchButton
                  disabled={dispatchedIds().includes(order.id_order)}
                  onPress={() => {
                    handleNavigation(id_order);
                  }}
                >
                  <ButtonTitle>Detalhes do pedido</ButtonTitle>
                </DispatchButton>
              )}
            </OrderCard>
          );
        })}
    </ScrollableList>
  );
}
