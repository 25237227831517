/* eslint-disable camelcase */
import moment from 'moment';
import React, {
  useCallback,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from 'react';
import uuid from 'react-native-uuid';
import { api } from '../services/brainApi';
import { useOrders } from './Orders';

type OrderEvent = {
  id_event: string;
  id_order_item_line: string;
  status: string;
};

type OrderIssueEvent = {
  id_event: string;
  status: string;
  order_status: string;
};

type OrderItemEventsContextData = {
  issueTypes: Array<string>;
  postSortItemEvent(id: string): Promise<OrderEvent>;
  postItemIssueEvent(
    id: string,
    reason: string,
    quantity_sorted: number,
    observations: string
  ): Promise<OrderIssueEvent>;
  postApproveItemEvent(id: string): Promise<OrderEvent>;
  postRefuseItemEvent(id: string): Promise<OrderEvent>;
  postItemPartialIssueEvent(
    id: string,
    reason: string,
    quantity_sorted: number,
    observations: string
  ): Promise<OrderIssueEvent>;
};

type OrdersItemEventsProviderProps = {
  children: ReactNode;
  isLoggedIn: boolean;
};

const OrderContext = createContext<OrderItemEventsContextData>(
  {} as OrderItemEventsContextData,
);

const GET_ISSUE_TYPES = String(process.env.GET_ISSUE_TYPES);
const POST_ITEM_EVENT_ENDPOINT = String(process.env.POST_ITEM_EVENT_ENDPOINT);

function OrderItemEventsProvider({
  children,
  isLoggedIn,
}: OrdersItemEventsProviderProps) {
  const { loadOrders } = useOrders();
  const [issueTypes, setIssueTypes] = useState<Array<string>>([]);

  const postSortItemEvent = useCallback(
    async (id: string): Promise<OrderEvent> => {
      const response = await api.post(POST_ITEM_EVENT_ENDPOINT, {
        type: 'SORT',
        id_order_item_line: id,
        id_event: uuid.v4(),
        occurred_at: moment(),
      });

      loadOrders();
      return response?.data;
    },
    [],
  );

  const postApproveItemEvent = useCallback(
    async (id: string): Promise<OrderEvent> => {
      const response = await api.post(POST_ITEM_EVENT_ENDPOINT, {
        type: 'APPROVE_PICKING',
        id_order_item_line: id,
        id_event: uuid.v4(),
        occurred_at: moment(),
      });

      loadOrders();
      return response?.data;
    },
    [],
  );

  const postRefuseItemEvent = useCallback(
    async (id: string): Promise<OrderEvent> => {
      const response = await api.post(POST_ITEM_EVENT_ENDPOINT, {
        type: 'REFUSE_PICKING',
        id_order_item_line: id,
        id_event: uuid.v4(),
        occurred_at: moment(),
      });

      loadOrders();
      return response?.data;
    },
    [],
  );

  const postItemIssueEvent = useCallback(
    async (
      id: string,
      reason: string,
      quantity_sorted: number,
      observations: string,
    ): Promise<OrderIssueEvent> => {
      const response = await api.post(POST_ITEM_EVENT_ENDPOINT, {
        type: 'ISSUE',
        id_event: uuid.v4(),
        id_order_item_line: id,
        occurred_at: moment(),
        reason,
        quantity_sorted,
        observations,
      });

      loadOrders();
      return response?.data;
    },
    [],
  );

  const postItemPartialIssueEvent = useCallback(
    async (
      id: string,
      reason: string,
      quantity_sorted: number,
      observations: string,
    ): Promise<OrderIssueEvent> => {
      const response = await api.post(POST_ITEM_EVENT_ENDPOINT, {
        type: 'SORT',
        id_event: uuid.v4(),
        id_order_item_line: id,
        occurred_at: moment(),
        reason,
        quantity_sorted,
        observations,
      });

      loadOrders();
      return response?.data;
    },
    [],
  );

  const fetchIssueTypes = useCallback(async (): Promise<void> => {
    const response = await api.get(GET_ISSUE_TYPES);
    setIssueTypes(response?.data);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      fetchIssueTypes();
    }
  }, [isLoggedIn]);

  const value = useMemo(
    () => ({
      issueTypes,
      postSortItemEvent,
      postApproveItemEvent,
      postRefuseItemEvent,
      postItemIssueEvent,
      postItemPartialIssueEvent,
    }),
    [issueTypes],
  );

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
}

function useOrderItemEvents(): OrderItemEventsContextData {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error(
      'userOrdersItemEvents must be used withing an OrdersItemEventsProvider',
    );
  }

  return context;
}

export { OrderItemEventsProvider, useOrderItemEvents };
