/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useMemo, useState } from 'react';
import { Switch } from 'react-native-paper';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  Container,
  IssueCounterContainer,
  IssueTypeContainer,
  SwitchContainer,
  Label,
  ObservationsInputContainer,
  ObservationsInput,
  Button,
  ButtonTitle,
  SwitchLabel,
} from './styles';
import { QuantitySelector } from '../../components/QuantitySelector';
import { ScrollableList } from '../../components/ScrollableList';
import { ItemIssueFormProps } from '../../types/navigation';
import { DropDown, DropDownItem } from '../../components/DropDown';
import { useOrderItemEvents } from '../../hooks/OrderItemEventsProvider';
import { AppLoading } from '../../components/AppLoading';
import { GoBackHeader } from '../../components/GoBackHeader';
import { ItemSummary } from '../../components/ItemSummary';

const labels: { [status: string]: string } = {
  OUT_OF_STOCK: 'Sem estoque',
  QUALITY: 'Qualidade',
  SHOPPING: 'Compra',
};

export function ItemIssueForm() {
  const route = useRoute();
  const { goBack } = useNavigation();
  const { issueTypes, postItemIssueEvent, postItemPartialIssueEvent } = useOrderItemEvents();

  const { item } = route.params as ItemIssueFormProps;

  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState<number>(0);
  const [fullBreakUp, setFullBreakUp] = useState<boolean>(false);
  const [observations, setObservations] = useState<string>('');
  const [issueType, setIssueType] = useState<DropDownItem>({} as DropDownItem);

  function handleFullBreakup() {
    if (fullBreakUp) {
      setCounter(item.ordered_quantity);
    } else {
      setCounter(0);
    }
  }

  const possibleIssues = useMemo(
    () => issueTypes
      .filter((issue) => issue !== 'RETOOL')
      .map((issue) => ({
        label: labels[issue],
        value: issue,
      }))
      .filter((i) => i.label && i.value),
    [],
  );

  async function handlePostItemIssue() {
    setLoading(true);

    const finishRequest = () => {
      setLoading(false);
      goBack();
    };

    if (fullBreakUp) {
      postItemIssueEvent(
        item.id_order_item,
        issueType.value,
        counter,
        observations,
      ).finally(finishRequest);
    } else {
      postItemPartialIssueEvent(
        item.id_order_item,
        issueType.value,
        counter,
        observations,
      ).finally(finishRequest);
    }
  }

  useEffect(handleFullBreakup, [fullBreakUp]);

  if (loading) return <AppLoading />;

  return (
    <>
      <GoBackHeader title="Detalhes da Ruptura" />
      <ScrollableList>
        <Container>
          <ItemSummary item={item} />
          <IssueTypeContainer>
            <Label>Selecione o tipo de ruptura</Label>
            <DropDown
              placeholder="Selecione o tipo de Ruptura"
              items={possibleIssues}
              value={issueType}
              onChange={setIssueType}
            />
          </IssueTypeContainer>
          <IssueCounterContainer>
            <Label>Quantidade para Envio</Label>
            <QuantitySelector
              disable={fullBreakUp}
              maxValue={item.ordered_quantity}
              minValue={0}
              counter={counter}
              onChange={setCounter}
            />
            <SwitchContainer>
              <Switch
                trackColor={{ false: '#767577', true: '#16563b' }}
                thumbColor={fullBreakUp ? '#16563b' : '#f4f3f4'}
                value={fullBreakUp}
                onValueChange={setFullBreakUp}
              />
              <SwitchLabel>Ruptura Total</SwitchLabel>
            </SwitchContainer>
          </IssueCounterContainer>
          <ObservationsInputContainer>
            <Label>Observações</Label>
            <ObservationsInput
              value={observations}
              onChangeText={setObservations}
              multiline
              selectionColor="#16563b"
            />
          </ObservationsInputContainer>
          <Button
            disabled={!issueType.value}
            onPress={() => handlePostItemIssue()}
          >
            <ButtonTitle>Confirmar</ButtonTitle>
          </Button>
        </Container>
      </ScrollableList>
    </>
  );
}
