import React, { useState } from 'react';
import { Octicons } from '@expo/vector-icons';
import {
  Container,
  SelectedValue,
  DropDownItem,
  Label,
  DropDownItemContainer,
} from './styles';
import theme from '../../theme';

export type DropDownItem = {
  label: string;
  value: string;
};

type DropDownProps = {
  items: Array<DropDownItem>;
  value: DropDownItem;
  placeholder: string;
  onChange(value: DropDownItem): void;
};

export function DropDown({
  items,
  value,
  placeholder,
  onChange,
}: DropDownProps) {
  const [open, setOpen] = useState(false);

  function handleOpenDropDown() {
    setOpen(!open);
  }

  function handleSelect(item: DropDownItem) {
    onChange(item);
    handleOpenDropDown();
  }

  return (
    <>
      <Container hideBottomBorder={!open} onPress={() => handleOpenDropDown()}>
        <SelectedValue>{value.label ? value.label : placeholder}</SelectedValue>
        {open ? (
          <Octicons
            name="triangle-up"
            size={16}
            color={theme.COLORS.PRIMARY_BRAND_DARK}
          />
        ) : (
          <Octicons
            name="triangle-down"
            size={16}
            color={theme.COLORS.PRIMARY_BRAND_DARK}
          />
        )}
      </Container>
      {open && (
        <DropDownItemContainer nestedScrollEnabled>
          {items.map((item) => (
            <DropDownItem key={item.value} onPress={() => handleSelect(item)}>
              <Label>{item.label}</Label>
            </DropDownItem>
          ))}
        </DropDownItemContainer>
      )}
    </>
  );
}
