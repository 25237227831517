import styled from 'styled-components/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import themeJSON from '../../theme';

export const CardContainer = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_WHITE};

  border-radius: 8px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};

  flex-direction: row;
  align-items: center;

  padding: 16px;
  margin-bottom: 8px;

  max-height: 220px;
`;

export const ContentWrapper = styled.View`
  flex: 1;
`;

export const IconWrapper = styled.View`
  align-items: center;
  justify-content: center;
  border-left-width: 1px;
  height: 100%;
  border-left-color: ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
`;

export const ArrowIcon = styled(MaterialCommunityIcons).attrs({
  size: 24,
  color: themeJSON.COLORS.PRIMARY_BRAND_DEFAULT,
})`
  margin-left: 12px;
`;

export const OrderDataContainer = styled.View`
  display: flex;
  width: 100%;
`;

export const OrderSpecificDataContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;s
  margin-right: 10px;
`;

export const ContactCompanyName = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TITLE};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;

  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;

export const OrderIdText = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-size: 14px;
  font-weight: 700;
  font-style: normal;

  margin-top: 4px;
  margin-right: 24px;
  color: ${({ theme }) => theme.COLORS.SECONDARY_BRAND_DEFAULT};
`;

export const DeliveredAtText = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-size: 14px;
  font-weight: 700;
  font-style: normal;

  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;

export const DeliveryWindowText = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-size: 14px;
  font-weight: 700;
  font-style: normal;

  padding: 2px
  border-radius: 4px;
  border: 1px ${({ theme }) => theme.COLORS.BACKGROUND_BRAND_LIGHT};
  margin-top: 4px;
  margin-right: 4px;

  color: ${({ theme }) => theme.COLORS.PRIMARY_BRAND_LIGHT};
`;

export const DeliveryWindowTimeInfo = styled.Text`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  font-size: 8px;
  align-items: center;
`;
export const DeliveryInformationContainer = styled.View`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SummaryText = styled.Text`
  font-family: ${({ theme }) => theme.FONTS.TEXT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-left: 6px;

  color: ${({ theme }) => theme.COLORS.TEXT_BRAND_GREY_DARK};
`;

export const OrderSummaryContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RuptureIconContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 24px;
`;
