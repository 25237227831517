import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ReviewOrder } from '../screens/ReviewOrder';
import { OrdersTabNavigator } from './orders.tab.routes';
import { ItemIssueForm } from '../screens/ItemIssueForm';
import { ConfirmDispatchOrderForm } from '../screens/ConfirmDispatchOrderForm';
import { ApproveOrder } from '../screens/ApproveOrder';

const { Navigator, Screen } = createNativeStackNavigator();

export function OrdersStackNavigator() {
  return (
    <Navigator screenOptions={{ headerShown: false }}>
      <Screen name="UserTabRoutes" component={OrdersTabNavigator} />
      <Screen name="reviewOrder" component={ReviewOrder} />
      <Screen name="approveOrder" component={ApproveOrder} />
      <Screen name="itemIssue" component={ItemIssueForm} />
      <Screen name="dispatchForm" component={ConfirmDispatchOrderForm} />
    </Navigator>
  );
}
