import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Session, User } from '@supabase/supabase-js';
import { getUserRoles, supabaseClient } from '../services/supabase';

interface AuthContextData {
  user: User | null;
  isAllowed: boolean;
  isLoading: boolean;
}

export const AuthContext = createContext<AuthContextData>({
  user: null,
  isAllowed: false,
  isLoading: false,
});

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<null | User>(null);
  const [isAllowed, setIsAllowed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const [roles, setRoles] = useState<string[]>([]);

  const checkIfUserIsLoggedIn = async () => {
    const sessionString = (await AsyncStorage.getItem('session')) ?? '{}';
    const session: Session = JSON.parse(sessionString);
    if (session.user) {
      setUser(session.user);
      setIsAllowed(true);
    }
  };

  const userSignedOut = async () => {
    console.info('Logging out...');
    setUser(null);
    AsyncStorage.removeItem('session');
  };

  const userSignedIn = async (session: Session) => {
    console.info('Logging in...');

    if (!session.user.email) {
      console.info('No email found in session, logging out...');
      await userSignedOut();
      return;
    }

    setIsLoading(true);
    const userRoles = await getUserRoles(session.user.email);
    console.log(userRoles);
    setIsLoading(false);

    if (userRoles.includes('clicampo')) {
      setUser(session.user);
      setIsAllowed(true);
      setRoles(userRoles);
      await AsyncStorage.setItem('session', JSON.stringify(session));
    } else {
      setIsAllowed(false);
      await AsyncStorage.removeItem('session');
    }
  };

  const auth = useMemo(
    () => ({
      user,
      isAllowed,
      isLoading,
    }),
    [user, isAllowed, isLoading],
  );

  useEffect(() => {
    checkIfUserIsLoggedIn();
    supabaseClient.auth.onAuthStateChange(async (event, session) => {
      // eslint-disable-next-line
      if (event === 'SIGNED_IN') {
        if (session) await userSignedIn(session);
        else await userSignedOut();
      } else if (event === 'SIGNED_OUT') await userSignedOut();
    });
  }, []);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used withing an AuthProvider');
  }

  return context;
}
