import React, { useMemo, useState, useEffect } from 'react';
import { ProgressBar } from 'react-native-paper';
import { useOrders } from '../../hooks/Orders';
import theme from '../../theme';
import { Order } from '../../types/types';
import { getFixedNumberString } from '../../utils';
import {
  BoldTitle,
  Container,
  ProgressBarContainer,
  ProgressBarSummary,
  Title,
} from './styles';

export function OrdersItemsProgressBar() {
  const {
    loadingOrders,
    pendingOrders,
    reviewedOrders,
    dispatchedOrders,
  } = useOrders();
  const [progress, setProgress] = useState(0);

  const totalItens = useMemo(() => {
    const getAllItens = (orders: Order[]) => orders.reduce((acc, order) => acc + order.items.length, 0);

    return (
      getAllItens(pendingOrders)
      + getAllItens(reviewedOrders)
      + getAllItens(dispatchedOrders)
    );
  }, [pendingOrders, reviewedOrders, dispatchedOrders]);

  const sortedItens = useMemo(() => {
    const getAllSortedItens = (orders: Order[]) => orders.reduce(
      (acc, order) => acc + order.items.filter((item) => item.is_item_reviewed).length,
      0,
    );

    return (
      getAllSortedItens(pendingOrders)
      + getAllSortedItens(reviewedOrders)
      + getAllSortedItens(dispatchedOrders)
    );
  }, [pendingOrders, reviewedOrders, dispatchedOrders]);

  useEffect(() => {
    if (totalItens !== 0) {
      setProgress(sortedItens / totalItens);
    }
  }, [sortedItens, totalItens]);

  if (loadingOrders) {
    return null;
  }

  return (
    <Container>
      <Title>
        <BoldTitle>
          {totalItens}
          {' '}
          produtos
          {' '}
        </BoldTitle>
        no total
      </Title>
      <ProgressBarContainer>
        <ProgressBar
          style={{ height: 5, borderRadius: 5, width: 100 }}
          progress={progress}
          color={theme.COLORS.PRIMARY_BRAND_LIGHT}
        />
        <ProgressBarSummary>
          {getFixedNumberString(progress)}
          %
        </ProgressBarSummary>
      </ProgressBarContainer>
    </Container>
  );
}
