import React, { ReactNode } from 'react';
import { Footer } from '../Footer';
import { Container } from './styles';

type ScrollableListProps = {
  children: ReactNode;
};

export function ScrollableList({ children }: ScrollableListProps) {
  return (
    <Container nestedScrollEnabled>
      {children}
      <Footer />
    </Container>
  );
}
