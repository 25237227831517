import { useCallback, useEffect, useState } from 'react';
import { api } from '../services/brainApi';

interface Metrics {
  ordersCount?: number;
}

async function fetchOrdersCount(idContactCompany: string): Promise<number> {
  const response = await api.get(`/crm/contact_company/${idContactCompany}?with_orders_count=true`);
  return response.data.orders_count;
}

export function useContactCompanyMetrics(idContactCompany: string) {
  const [metrics, setMetrics] = useState<Metrics | null>(null);

  const fetchMetrics = useCallback(async () => {
    const ordersCount = await fetchOrdersCount(idContactCompany);

    setMetrics({
      ordersCount,
    });
  }, [idContactCompany]);

  useEffect(() => {
    fetchMetrics();
  }, []);

  return metrics;
}
