/**
 * This is the navigation stack presented for the guest user.
 * It will present the options to login and register.
 * Unauthenticated users will be presented with this stack.
 */

import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { LoginScreen } from '../screens/Login';

const { Navigator, Screen } = createNativeStackNavigator();

export function GuestStackNavigator() {
  return (
    <Navigator screenOptions={{ headerShown: false }}>
      <Screen name="Login" component={LoginScreen} />
    </Navigator>
  );
}
