/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
  useRoute,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons';

import { AppLoading } from '../../components/AppLoading';
import { ReviewOrderProps } from '../../types/navigation';
import { ContactCompanySummary } from '../../components/ContactCompanySummary';
import { OrderProgressBar } from '../../components/OrderProgressBar';
import { ScrollableList } from '../../components/ScrollableList';
import { Item, Order } from '../../types/types';
import { useOrders } from '../../hooks/Orders';
import { OrderItemCard } from '../../components/OrderItemCard';
import {
  ButtonContainer,
  ButtonText,
  ItemButton,
  OrderStatusContainer,
  StatusTitle,
} from './styles';
import { GoBackHeader } from '../../components/GoBackHeader';
import { useOrderItemEvents } from '../../hooks/OrderItemEventsProvider';
import theme from '../../theme';

export function ApproveOrder() {
  const { navigate } = useNavigation();
  const route = useRoute();
  const orders = useOrders();
  const orderItemEvents = useOrderItemEvents();
  const isFocused = useIsFocused();
  const { id } = route.params as ReviewOrderProps;
  const [order, setOrder] = useState<Order>();

  const fetchOrder = useCallback(async (): Promise<void> => {
    const fetchedOrder = await orders.getOrderById(id);

    setOrder(fetchedOrder);
  }, []);

  useEffect(() => {
    fetchOrder();
  }, [isFocused]);

  function handleConfirmApprove(orderItemLineId: string) {
    orderItemEvents
      .postApproveItemEvent(orderItemLineId)
      .finally(() => fetchOrder());
  }

  function handleConfirmRefuse(orderItemLineId: string) {
    orderItemEvents
      .postRefuseItemEvent(orderItemLineId)
      .finally(() => fetchOrder());
  }

  function handleIssueForm(item: Item) {
    navigate('itemIssue', {
      item,
    });
  }

  if (!order) {
    return <AppLoading />;
  }
  return (
    <>
      <GoBackHeader title={`Pedido #${order.id_commercial}`} />
      <ScrollableList>
        <ContactCompanySummary contactCompany={order.contact_company} />
        {(
          order.items.every((i) => i.status === 'PICKING_APPROVED'
            && ['SORTED', 'PICKING_REFUSED'].includes(order.status))
          && (
            <ItemButton
              type="separate"
              onPress={() => handleConfirmApprove(order.items[0].id_order_item)}
            >
              <ButtonText type="separate">
                <MaterialCommunityIcons
                  name="check"
                  size={14}
                  color={theme.COLORS.PRIMARY_BRAND_DEFAULT}
                />
                Forçar aprovação do pedido
              </ButtonText>
            </ItemButton>
          )
        )}
        {!order.is_order_dispatched
          && (
            <OrderProgressBar
              items={order.items}
              itemEvaluator={(item) => item.is_item_picking_approved}
            />
          )}
        {order.is_order_dispatched && (
          <OrderStatusContainer>
            <Entypo
              name="check"
              size={16}
              color={theme.COLORS.PRIMARY_BRAND_DEFAULT}
            />
            <StatusTitle>Entrega despachada </StatusTitle>
          </OrderStatusContainer>
        )}
        {order.items.map((item) => {
          const {
            sku,
            ordered_quantity,
            shipped_quantity,
            fulfilment_status,
            id_order_item,
            status,
            capabilities,
            observation,
          } = item;

          return (
            <OrderItemCard
              capabilities={capabilities}
              quantityOrdered={ordered_quantity}
              key={id_order_item}
              quantitySorted={shipped_quantity}
              skuName={sku.description}
              skuQuantity={sku.quantity}
              skuUnit={sku.unit}
              orderItemStatus={fulfilment_status}
              orderItemObservation={observation}
              itemCurrentStatus={status}
            >
              <ButtonContainer>
                {!order.is_order_dispatched && (
                  <ItemButton
                    type="problem"
                    onPress={() => handleConfirmRefuse(id_order_item)}
                  >
                    <ButtonText type="problem">
                      <MaterialCommunityIcons
                        name="tag-off"
                        size={14}
                        color="#d44949"
                      />
                      {' Recusar'}
                    </ButtonText>
                  </ItemButton>
                )}

                {capabilities.can_update_item && !order.is_order_dispatched && (
                  <ItemButton
                    onPress={() => handleIssueForm(item)}
                    type="update"
                    variableSize
                  >
                    <ButtonText type="update">
                      <MaterialCommunityIcons
                        name="pencil-outline"
                        size={14}
                        color="#d44949"
                      />
                    </ButtonText>
                  </ItemButton>
                )}
                {!(order.is_order_dispatched || status === 'PICKING_APPROVED') && (
                  <ItemButton
                    type="separate"
                    onPress={() => handleConfirmApprove(id_order_item)}
                  >
                    <ButtonText type="separate">
                      <MaterialCommunityIcons
                        name="check"
                        size={14}
                        color={theme.COLORS.PRIMARY_BRAND_DEFAULT}
                      />
                      {' Aprovar'}
                    </ButtonText>
                  </ItemButton>
                )}
              </ButtonContainer>
            </OrderItemCard>
          );
        })}

      </ScrollableList>
    </>
  );
}
