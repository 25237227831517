import React, { ReactNode } from 'react';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import {
  CardContainer,
  ContactCompanyName,
  DeliveredAtText,
  DeliveryWindowTimeInfo,
  OrderIdText,
  OrderSummaryContainer,
  OrderDataContainer,
  OrderSpecificDataContainer,
  SummaryText,
  ContentWrapper,
  ArrowIcon,
  IconWrapper,
  DeliveryWindowText,
  DeliveryInformationContainer,
  RuptureIconContainer,
} from './styles';
import theme from '../../theme';
import { DeliveryCategory } from '../../types/types';

type OrderCardProps = {
  idOrder: string;
  contactCompanyName: string;
  deliveryWindow?: DeliveryCategory;
  deliveredAt: string;
  orderNumber: number;
  productsCount: number;
  isPendingOrder?: boolean;
  children: ReactNode | undefined;
  cardNavigationDestination?: string;
  status?: string;
  hasRupturedItems?: boolean;
};

export function OrderCard({
  children,
  contactCompanyName,
  deliveredAt,
  deliveryWindow,
  orderNumber,
  productsCount,
  idOrder,
  isPendingOrder,
  cardNavigationDestination,
  status,
  hasRupturedItems,
}: OrderCardProps) {
  const { navigate } = useNavigation();

  function handleNavigation() {
    // const destination = cardNavigationDestination || 'reviewOrder';
    navigate(cardNavigationDestination || 'reviewOrder', { id: idOrder });
  }

  function formatTime(time?: string) {
    const splitted = time?.split(':');
    if (splitted?.[0]) {
      splitted[0] = String(parseInt(splitted[0], 10) - 3);
    }

    splitted?.pop();
    return splitted?.join(':');
  }

  function deliveryCategoryIcon(name?: string) {
    if (name === 'Premium') return '⭐️ ';
    if (name === 'Comfort') return '🐻 ';
    return '';
  }

  return (
    <CardContainer onPress={() => handleNavigation()}>
      <ContentWrapper>
        <OrderDataContainer>

          <RuptureIconContainer>
            <ContactCompanyName>{contactCompanyName}</ContactCompanyName>
            <ContactCompanyName>
              {
                hasRupturedItems
                && (
                  <AntDesign
                    name="warning"
                    size={24}
                    color={theme.COLORS.WARNING_ORANGE}
                  />
                )
              }
            </ContactCompanyName>
          </RuptureIconContainer>

          <OrderSpecificDataContainer>
            <OrderIdText>
              Pedido #
              {orderNumber}
            </OrderIdText>
            <OrderIdText>
              {
                status === 'PICKING_REFUSED' && (
                  <AntDesign
                    name="warning"
                    size={24}
                    color={theme.COLORS.SECONDARY_BRAND_DARK}
                  />
                )
              }
            </OrderIdText>
          </OrderSpecificDataContainer>

          <DeliveryInformationContainer>
            <DeliveredAtText>Entrega: {deliveredAt}</DeliveredAtText>
            {deliveryWindow && (
              <DeliveryWindowText>
                {deliveryWindow?.name}
                <DeliveryWindowTimeInfo>
                  {deliveryCategoryIcon(deliveryWindow.name)}
                  {formatTime(deliveryWindow?.timewindow_start)}
                  {deliveryWindow && ' - '}
                  {formatTime(deliveryWindow?.timewindow_end)}
                </DeliveryWindowTimeInfo>
              </DeliveryWindowText>
            )}
          </DeliveryInformationContainer>

          <OrderSummaryContainer>
            <MaterialCommunityIcons
              name="cart-outline"
              size={18}
              color="#0C7249"
            />
            <SummaryText>
              {productsCount}
              {' '}
              produtos
            </SummaryText>
          </OrderSummaryContainer>
        </OrderDataContainer>
        {children}
      </ContentWrapper>

      {isPendingOrder && (
        <IconWrapper>
          <ArrowIcon name="chevron-right" />
        </IconWrapper>
      )}
    </CardContainer>
  );
}
