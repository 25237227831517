import React from 'react';
import { User } from '@supabase/supabase-js';
import { AuthContext } from '../../hooks/Auth';
import { redirectUrl, supabaseClient } from '../../services/supabase';
import {
  Container,
  HeaderText,
  LoginArea,
  LoginWithGoogleButton,
  NotAllowedText,
  Text,
  LoadingText,
} from './styles';

const loginWithGoogle = () => {
  supabaseClient.auth.signInWithOAuth(
    {
      provider: 'google',
      options: {
        redirectTo: redirectUrl,
        queryParams: {
          prompt: 'consent',
        },
      },
    },
  );
};

function UserNotLogged({ user }: { user: User }) {
  return (
    <LoginArea>
      {user === null ? (
        <>
          <HeaderText>Bem vindo!</HeaderText>
          <LoginWithGoogleButton
            onPress={() => {
              loginWithGoogle();
            }}
          >
            <Text>Login com Google</Text>
          </LoginWithGoogleButton>
        </>
      ) : (
        <NotAllowedText>
          Você não está permitido nessa aplicação.
        </NotAllowedText>
      )}
    </LoginArea>
  );
}

function IsLoading() {
  return (
    <LoginArea>
      <LoadingText>Verificando permissões...</LoadingText>
    </LoginArea>
  );
}

export function LoginScreen() {
  return (
    <Container>
      <AuthContext.Consumer>
        {(auth) => {
          if (auth.isLoading) return <IsLoading />;
          return <UserNotLogged user={auth.user as User} />;
        }}
      </AuthContext.Consumer>
    </Container>
  );
}
