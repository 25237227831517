/* eslint-disable global-require */
/* eslint-disable react/style-prop-object */
/* eslint-disable camelcase */
import React from 'react';
import { ThemeProvider } from 'styled-components/native';
import { useFonts } from 'expo-font';
import AppLoading from 'expo-app-loading';
import { StatusBar } from 'expo-status-bar';
import * as Sentry from 'sentry-expo';
import { Header } from './src/components/Header';
import { AppProvider } from './src/hooks';
import { AuthContext, AuthProvider } from './src/hooks/Auth';
import { Routes } from './src/routes';
import theme from './src/theme';

import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';

Sentry.init({
  dsn: 'https://0ff4e5993dc342708750fcb8b7bc4c02@o1123504.ingest.sentry.io/6313964',
  environment: process.env.SENTRY_ENVIRONMENT ?? 'dev',
  enableInExpoDevelopment: true,
  sampleRate: 1,
});

export default function App() {
  const [fontsLoaded] = useFonts({
    Nunito: require('./src/assets/fonts/Nunito-Regular.ttf'),
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }
  return (
    <AuthProvider>
      <AppProvider>
        <ThemeProvider theme={theme}>
          <StatusBar style="light" translucent backgroundColor="transparent" />
          <AuthContext.Consumer>
            {(auth) => (auth.user !== null && auth.isAllowed ? (
              <>
                <Header />
                <Routes />
              </>
            ) : (
              <Routes />
            ))}
          </AuthContext.Consumer>
        </ThemeProvider>
      </AppProvider>
    </AuthProvider>
  );
}

serviceWorkerRegistration.register();
