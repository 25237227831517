import React, {
  useCallback,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from 'react';
import debounce from 'awesome-debounce-promise';
import { Order } from '../types/types';
import { useOrders } from './Orders';

type OrdersViewProviderProps = {
  children: ReactNode;
};

type OrdersViewContextData = {
  pendingOrdersView: Order[];
  reviewedOrdersView: Order[];
  approvedOrdersView: Order[];
  refusedOrdersView: Order[];
  dispatchedOrdersView: Order[];
  search: string;
  setSearch: (value: string) => void;
  filterViewWith: (filter: string) => void;
};

const OrdersViewContext = createContext<OrdersViewContextData>(
  {} as OrdersViewContextData,
);

function OrdersViewProvider({ children }: OrdersViewProviderProps) {
  const [pendingOrdersView, setPendingOrdersView] = useState<Order[]>([]);
  const [reviewedOrdersView, setReviewedOrdersView] = useState<Order[]>([]);
  const [approvedOrdersView, setApprovedOrdersView] = useState<Order[]>([]);
  const [refusedOrdersView, setRefusedOrdersView] = useState<Order[]>([]);
  const [dispatchedOrdersView, setDispatchedOrdersView] = useState<Order[]>([]);
  const [search, setSearch] = useState('');

  const {
    pendingOrders, reviewedOrders, approvedOrders, dispatchedOrders, refusedOrders,
  } = useOrders();

  const resetViews = useCallback(() => {
    setPendingOrdersView(pendingOrders);
    setReviewedOrdersView(reviewedOrders);
    setApprovedOrdersView(approvedOrders);
    setRefusedOrdersView(refusedOrders);
    setDispatchedOrdersView(dispatchedOrders);
  }, [pendingOrders, reviewedOrders, dispatchedOrders, approvedOrders, refusedOrders]);

  useEffect(() => {
    resetViews();
    setSearch('');
  }, [pendingOrders, reviewedOrders, dispatchedOrders, approvedOrders, refusedOrders]);

  const filterViewWith = useCallback(
    (filter: string) => {
      const filterOrders = (orders: Order[], value: string) => orders.filter(
        (order: Order) => String(order.id_commercial).includes(value)
          || order.contact_company.name.toLowerCase().includes(value.toLowerCase()),
      );

      setPendingOrdersView(filterOrders(pendingOrders, filter));
      setReviewedOrdersView(filterOrders(reviewedOrders, filter));
      setApprovedOrdersView(filterOrders(approvedOrders, filter));
      setRefusedOrdersView(filterOrders(refusedOrders, filter));
      setDispatchedOrdersView(filterOrders(dispatchedOrders, filter));
    },
    [pendingOrders, reviewedOrders, dispatchedOrders],
  );

  useEffect(() => {
    if (search) {
      debounce(() => filterViewWith(search), 300)();
    } else {
      resetViews();
    }
  }, [search]);

  const value = useMemo(
    () => ({
      pendingOrdersView,
      reviewedOrdersView,
      approvedOrdersView,
      refusedOrdersView,
      dispatchedOrdersView,
      filterViewWith,
      search,
      setSearch,
    }),
    [
      pendingOrdersView,
      approvedOrdersView,
      refusedOrdersView,
      reviewedOrdersView,
      dispatchedOrdersView,
      search,
    ],
  );

  return (
    <OrdersViewContext.Provider value={value}>
      {children}
    </OrdersViewContext.Provider>
  );
}

function useOrdersView(): OrdersViewContextData {
  const context = useContext(OrdersViewContext);

  if (!context) {
    throw new Error('useOrdersView must be used withing an OrdersViewProvider');
  }

  return context;
}

export { OrdersViewProvider, useOrdersView };
