import styled from 'styled-components/native';
import theme from '../../theme';

export const Container = styled.View`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const LoadingIcon = styled.ActivityIndicator.attrs({
  size: 60,
  color: theme.COLORS.PRIMARY_BRAND_DEFAULT,
})``;

export const Label = styled.Text`
  margin-top: 20px;
  color: ${theme.COLORS.PRIMARY_BRAND_DEFAULT};
  font-size: 16px;
  font-weight: 500;
`;
