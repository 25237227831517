import React, { useMemo, useState } from 'react';
import { Image } from 'react-native';
import {
  Container,
  ContactCompanyName,
  SummaryContainer,
  PhotoContainer,
  SeeNotesBtn,
  SeeNotesBtnText,
  Notes,
  NotesTitle,
  NotesText,
  NewCompanyWarning,
  NotesHeader,
} from './styles';
import defaultImage from '../../assets/no-image.png';
import { ContactCompany } from '../../types/types';
import { useContactCompanyMetrics } from '../../hooks/contactCompanyMetrics';

type ContactCompanySummaryProps = {
  contactCompany: ContactCompany;
};

export function ContactCompanySummary({ contactCompany }: ContactCompanySummaryProps) {
  const metrics = useContactCompanyMetrics(contactCompany.id_contact_company);
  const [showNotes, setShowNotes] = useState(!!contactCompany.notes);

  const isNewCompany = useMemo(() => {
    if (metrics?.ordersCount) {
      return metrics.ordersCount < 3;
    }

    return false;
  }, [metrics?.ordersCount]);

  return (
    <>
      <Container>
        <PhotoContainer>
          <Image source={defaultImage} defaultSource={defaultImage} />
        </PhotoContainer>
        <SummaryContainer>
          {isNewCompany && (
            <NewCompanyWarning>
              Novo cliente!
            </NewCompanyWarning>
          )}

          <ContactCompanyName>
            {contactCompany.name}
          </ContactCompanyName>
        </SummaryContainer>
      </Container>
      {contactCompany.notes && (
        <Notes>
          <NotesHeader>
            <NotesTitle>Observações</NotesTitle>
            <SeeNotesBtn onPress={() => setShowNotes(!showNotes)}>
              <SeeNotesBtnText>
                {showNotes ? 'Ocultar' : 'Expandir'}
              </SeeNotesBtnText>
            </SeeNotesBtn>
          </NotesHeader>

          {showNotes && (<NotesText>{contactCompany.notes}</NotesText>)}
        </Notes>
      )}
    </>
  );
}
