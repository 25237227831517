import moment from 'moment';
import 'moment/locale/pt-br';
import { User } from '@supabase/supabase-js';
import React, { useEffect, useState } from 'react';
import { FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import { useOrdersView } from '../../hooks/OrdersView';
import {
  ButtonLabel,
  ConfirmButton,
  Container,
  Input,
  InputContainer,
  SearchContainer,
  Title,
  TitleContainer,
  TopContainer,
  IconButton,
  ProfileArea,
  ProfileImage,
} from './styles';
import theme from '../../theme';
import { AuthContext } from '../../hooks/Auth';
import { supabaseClient } from '../../services/supabase';

function UserInfo({ user }: { user: User }) {
  const [profileImageUrl, setProfileImageUrl] = useState<null | string>(null);
  useEffect(() => {
    // eslint-disable-next-line
    for (let identity of user.identities ?? []) {
      const avatarUrl = identity?.identity_data?.avatar_url;
      if (avatarUrl) {
        setProfileImageUrl(avatarUrl);
        break;
      }
    }
  }, []);

  const logOut = () => {
    supabaseClient.auth.signOut();
  };

  return profileImageUrl ? (
    <ProfileArea onPress={() => logOut()}>
      { profileImageUrl ? <ProfileImage source={{ uri: profileImageUrl }} /> : 'Sair'}
    </ProfileArea>
  ) : null;
}

export function Header() {
  const { search, setSearch } = useOrdersView();
  const today = moment(Date.now())
    .add(2, 'hours')
    .locale('pt-br')
    .format('DD [de] MMMM [de] YYYY');

  return (

    <Container>

      <TopContainer>
        <AuthContext.Consumer>
          {(auth) => (auth?.user !== null ? <UserInfo user={auth.user} /> : null)}
        </AuthContext.Consumer>
        <TitleContainer>
          <MaterialIcons
            name="calendar-today"
            size={20}
            color={theme.COLORS.BACKGROUND_BRAND_WHITE}
          />
          <Title>
            {today}
          </Title>
        </TitleContainer>
        <IconButton>
          <FontAwesome5
            name={'search'}
            size={20}
            color={theme.COLORS.BACKGROUND_BRAND_WHITE}
          />
        </IconButton>
      </TopContainer>

      <SearchContainer>
        <InputContainer>
          <Input
            onChangeText={setSearch}
            value={search}
            placeholder="Buscar pedido"
          />

          <ConfirmButton onPress={() => setSearch('')}>
            <ButtonLabel>Limpar</ButtonLabel>
          </ConfirmButton>
        </InputContainer>
      </SearchContainer>
    </Container>
  );
}
