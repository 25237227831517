/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
  useRoute,
  useNavigation,
  useIsFocused,
} from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons';

import { AppLoading } from '../../components/AppLoading';
import { ReviewOrderProps } from '../../types/navigation';
import { ContactCompanySummary } from '../../components/ContactCompanySummary';
import { OrderProgressBar } from '../../components/OrderProgressBar';
import { ScrollableList } from '../../components/ScrollableList';
import { Item, Order } from '../../types/types';
import { useOrders } from '../../hooks/Orders';
import { OrderItemCard } from '../../components/OrderItemCard';
import {
  ButtonContainer,
  ButtonText,
  ItemButton,
  OrderStatusContainer,
  StatusTitle,
} from './styles';
import { GoBackHeader } from '../../components/GoBackHeader';
import { useOrderItemEvents } from '../../hooks/OrderItemEventsProvider';
import theme from '../../theme';

export function ReviewOrder() {
  const route = useRoute();
  const orders = useOrders();
  const orderItemEvents = useOrderItemEvents();
  const { navigate } = useNavigation();
  const isFocused = useIsFocused();
  const { id } = route.params as ReviewOrderProps;
  const [order, setOrder] = useState<Order>();

  const fetchOrder = useCallback(async (): Promise<void> => {
    const fetchedOrder = await orders.getOrderById(id);

    setOrder(fetchedOrder);
  }, []);

  useEffect(() => {
    fetchOrder();
  }, [isFocused]);

  function handleConfirmSort(orderItemLineId: string) {
    orderItemEvents
      .postSortItemEvent(orderItemLineId)
      .finally(() => fetchOrder());
  }

  function handleIssueForm(item: Item) {
    navigate('itemIssue', {
      item,
    });
  }

  const orderItemsSorted = [
    ...order?.items.filter((i) => i.status === 'PICKING_REFUSED') || [],
    ...order?.items.filter((i) => i.status !== 'PICKING_REFUSED') || [],
  ];

  if (!order) {
    return <AppLoading />;
  }
  return (
    <>
      <GoBackHeader title={`Pedido #${order.id_commercial}`} />
      <ScrollableList>
        <ContactCompanySummary contactCompany={order.contact_company} />
        {!order.is_order_dispatched && <OrderProgressBar items={order.items} />}
        {order.is_order_dispatched && (
          <OrderStatusContainer>
            <Entypo
              name="check"
              size={16}
              color={theme.COLORS.PRIMARY_BRAND_DEFAULT}
            />
            <StatusTitle>Entrega despachada </StatusTitle>
          </OrderStatusContainer>
        )}
        {orderItemsSorted.map((item) => {
          const {
            sku,
            ordered_quantity,
            shipped_quantity,
            fulfilment_status,
            id_order_item,
            capabilities,
            is_item_reviewed,
            observation,
            status,
          } = item;
          return (
            !(status === 'PICKING_APPROVED') && (
              <OrderItemCard
                capabilities={capabilities}
                quantityOrdered={ordered_quantity}
                key={id_order_item}
                quantitySorted={shipped_quantity}
                skuName={sku.description}
                skuQuantity={sku.quantity}
                skuUnit={sku.unit}
                orderItemStatus={fulfilment_status}
                orderItemObservation={observation}
                itemCurrentStatus={status}
              >
                <ButtonContainer>
                  {!is_item_reviewed && !order.is_order_dispatched && (
                    <ItemButton
                      type="problem"
                      onPress={() => handleIssueForm(item)}
                    >
                      <ButtonText type="problem">
                        <MaterialCommunityIcons
                          name="package-variant"
                          size={14}
                          color="#d44949"
                        />
                        {' Ruptura'}
                      </ButtonText>
                    </ItemButton>
                  )}

                  {capabilities.can_update_item && !(
                    order.is_order_dispatched || order.status === 'PICKING_REFUSED'
                  ) && (
                    <ItemButton
                      onPress={() => handleIssueForm(item)}
                      type="update"
                    >
                      <ButtonText type="update">
                        <MaterialCommunityIcons
                          name="pencil-outline"
                          size={14}
                          color="#d44949"
                        />
                        {' Editar'}
                      </ButtonText>
                    </ItemButton>
                  )}

                  {!order.is_order_dispatched && (
                    <ItemButton
                      type="separate"
                      onPress={() => handleConfirmSort(id_order_item)}
                    >
                      <ButtonText type="separate">
                        <MaterialCommunityIcons
                          name="package-variant-closed"
                          size={14}
                          color={theme.COLORS.PRIMARY_BRAND_DEFAULT}
                        />
                        {' Separado'}
                      </ButtonText>
                    </ItemButton>
                  )}
                </ButtonContainer>
              </OrderItemCard>
            )
          );
        })}
      </ScrollableList>
    </>
  );
}
