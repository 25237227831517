import AsyncStorage from '@react-native-async-storage/async-storage';
import { createClient } from '@supabase/supabase-js';
import { getAppUrl } from '../utils/app-url';

const supabaseUrl = String(process.env.SUPABASE_URL);
const supabaseKey = String(process.env.SUPABASE_KEY);

if (!supabaseUrl || !supabaseKey) {
  console.error('[Supabase] URL and Key not found in environment variables.');
}

export const redirectUrl = getAppUrl();

if (!redirectUrl) {
  console.error('[Supabase] Redirect URL not found in environment variables');
}

export const supabaseClient = createClient(
  supabaseUrl,
  supabaseKey,
  {
    autoRefreshToken: true,
    localStorage: AsyncStorage,
  },
);

export const getUserRoles = async (userEmail: string) => supabaseClient
  .from('auth_user')
  .select('role')
  .eq('email', userEmail)
  .then((res) => res.data?.pop()?.role ?? '');
