import React, { useEffect, useMemo, useState } from 'react';
import { ProgressBar } from 'react-native-paper';
import { Item } from '../../types/types';
import theme from '../../theme';
import { getFixedNumberString } from '../../utils';
import {
  BoldTitle,
  Container,
  ProgressBarContainer,
  ProgressBarSummary,
  Title,
} from './styles';

type OrderProgressBarProps = {
  items: Array<Item>;
  itemEvaluator?: (item: Item) => boolean;
};

export function OrderProgressBar({ items, itemEvaluator }: OrderProgressBarProps) {
  const [progress, setProgress] = useState(0);
  const totalItems = useMemo(() => items.length, []);
  const progressLabel = useMemo(
    () => getFixedNumberString(progress),
    [progress],
  );
  const usedEvaluator = (itemEvaluator || ((item) => item.is_item_reviewed));
  const updateProgress = () => {
    const sortedItems = items.filter((item) => usedEvaluator(item)).length;
    const current = sortedItems / totalItems;
    setProgress(current);
  };

  useEffect(updateProgress, [items, usedEvaluator]);

  return (
    <Container>
      <Title>
        <BoldTitle>
          {totalItems}
          {' '}
          produtos
          {' '}
        </BoldTitle>
        no total
      </Title>
      <ProgressBarContainer>
        <ProgressBar
          style={{ height: 6, borderRadius: 46, width: 150 }}
          progress={progress}
          color={theme.COLORS.PRIMARY_BRAND_LIGHT}
        />
        <ProgressBarSummary>
          {progressLabel}
          % Separados
        </ProgressBarSummary>
      </ProgressBarContainer>
    </Container>
  );
}
