import React from 'react';
import { OrdersItemsProgressBar } from '../OrdersItemsProgressBar';
import { Container } from './styles';

export function SubHeader() {
  return (
    <Container>
      <OrdersItemsProgressBar />
    </Container>
  );
}
