import React, {
  useCallback,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import uuid from 'react-native-uuid';
import moment from 'moment';
import { Order } from '../types/types';
import { api } from '../services/brainApi';

const GET_ORDER_ENDPOINT = String(process.env.GET_ORDER_ENDPOINT);
const GET_ORDERS_ENDPOINT = String(process.env.GET_ORDERS_ENDPOINT);
const POST_ORDER_EVENT_ENDPOINT = String(process.env.POST_ORDER_EVENT_ENDPOINT);

type OrdersProviderProps = {
  children: ReactNode;
};

type OrderDispatchEvent = {
  id_event: string;
  status: string;
};

type OrdersContextData = {
  loadingOrders: boolean;
  pendingOrders: Array<Order>;
  reviewedOrders: Array<Order>;
  refusedOrders: Array<Order>;
  approvedOrders: Array<Order>;
  dispatchedOrders: Array<Order>;
  getOrderById(id: string): Promise<Order>;
  loadOrders(location?: string): Promise<void>;
  postDispatchOrderEvent(id: string): Promise<OrderDispatchEvent>;
};

const OrderContext = createContext<OrdersContextData>({} as OrdersContextData);

function OrdersProvider({ children }: OrdersProviderProps) {
  const [loadingOrders, setLoadingOrders] = useState<boolean>(false);
  const [pendingOrders, setPendingOrders] = useState<Array<Order>>([]);
  const [reviewedOrders, setReviewedOrders] = useState<Array<Order>>([]);
  const [approvedOrders, setApprovedOrders] = useState<Array<Order>>([]);
  const [refusedOrders, setRefusedOrders] = useState<Order[]>([]);
  const [dispatchedOrders, setDispatchedOrders] = useState<Array<Order>>([]);

  const getOrders = async () => {
    setLoadingOrders(true);

    const response = await api.get(GET_ORDERS_ENDPOINT);
    const orders: Record<string, Order[]> = response?.data;

    setLoadingOrders(false);
    setPendingOrders(orders?.PLACED ?? []);
    setReviewedOrders(orders?.REVIEWED ?? []);
    setApprovedOrders(orders?.PICKING_APPROVED ?? []);
    setRefusedOrders(orders?.PICKING_REFUSED ?? []);
    setDispatchedOrders(orders?.DISPATCHED ?? []);
  };

  const getOrderById = useCallback(async (id: string): Promise<Order> => {
    const response = await api.get(`${GET_ORDER_ENDPOINT}/${id}`);

    return response?.data;
  }, []);

  const loadOrders = useCallback(async (): Promise<void> => {
    await getOrders();
  }, []);

  const postDispatchOrderEvent = useCallback(
    async (id: string): Promise<OrderDispatchEvent> => {
      const response = await api.post(
        POST_ORDER_EVENT_ENDPOINT,
        {
          type: 'DISPATCH',
          id_order: id,
          id_event: uuid.v4(),
          occurred_at: moment(),
        },
      );

      await loadOrders();
      return response?.data;
    },
    [],
  );

  const value = useMemo(
    () => ({
      loadingOrders,
      pendingOrders,
      approvedOrders,
      refusedOrders,
      reviewedOrders,
      dispatchedOrders,
      loadOrders,
      getOrderById,
      postDispatchOrderEvent,
    }),
    [pendingOrders, reviewedOrders, dispatchedOrders],
  );

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
}

function useOrders(): OrdersContextData {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error('useOrders must be used withing an OrdersProvider');
  }

  return context;
}

export { OrdersProvider, useOrders };
